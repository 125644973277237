import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';


import { formatDate } from 'utils/dateUtils';

function BlogPost(props) {
  const { post } = props;
  const url = `/blog/${post.slug}/`;

  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 col-md-8 col-sm-10 col-12 blog-post">
        <Link to={url} className="primary-link" activeClassName=""><h2>{post.title}</h2></Link>
        <Link to={url}>
          <Img
            className="blog-post-image"
            fluid={post.thumbnailImg.childImageSharp.fluid}
            fadeIn={false}
          />
        </Link>
        {/* <Link to={url}><img src={post.thumbnailUrl}/></Link> */}

        <p className="published">{`Published: ${formatDate(post.datePublished)}`}</p>

        <p>{post.description}</p>

        <Link className="primary-button" to={url}>Read More</Link>
      </div>
    </div>
  );
}

export default BlogPost;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../js/components/Layout';
import SEO from '../components/SEO';
import BlogPost from '../js/screens/BlogScreen/components/BlogPost/BlogPost';

export default () => {
  let { allBlogPost: blogPosts } = useStaticQuery(graphql`
    query {
      allBlogPost {
        edges {
          node {
            id
            title
            slug
            description
            thumbnailUrl
            thumbnailImg {
              childImageSharp {
                fluid(sizes: """
                  (max-width: 300px) 200px,
                  (max-width: 600px) 400px,
                  1200px""", 
                  quality: 95, fit: COVER
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            datePublished
          }
        }
      }
    }
  `) || [];
  blogPosts = blogPosts.edges.map(p => p.node);
  blogPosts.sort((a, b) => (a.datePublished > b.datePublished) ? -1 : ((b.datePublished > a.datePublished) ? 1 : 0));
  return (
    <Layout>
      <SEO
        title={"Green Culture Studio Landscape Design Blog"}
        description={"Want to learn more about landscape design? Read our blog for tips and tricks on making the most of your garden."}
      />

      <div className="blog-container">
        {
          Array.isArray(blogPosts) && !blogPosts.length
            ? <h4>Coming soon...</h4>
            : (blogPosts || []).map(
              post => <BlogPost key={post.id} post={post} />,
            )
        }
      </div>
    </Layout>
  );
};
